<template>
  <div class="modal is-large is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Responder reclamo</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Mensaje</label>
            <div class="control">
              <textarea
                class="textarea"
                type="text"
                v-model="message"
                name="message"
                :class="{ 'is-danger': vverrors.first('message') }"
                v-validate="'required'"
              ></textarea>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
            <div class="control">
              <button class="button button-givu" :class="{ 'is-loading': loading }" :disabled="loading" @click="saveResponseLocal()">Guardar</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function
    },
    catalogCategoriesList: {
      type: Array
    },
    saveResponse: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      message: null,
      loading: false
    }
  },
  methods: {
    async saveResponseLocal () {
      let data = {
        message: this.message
      }
      if (await this.$validator.validateAll()) {
        this.loading = true
        this.saveResponse(data)
      }
    }
  }
}
</script>
